
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({})
export default class SingleGModal extends Vue {

    @Prop({
        default: () => new OM.SingleGModel()
    }) model: OM.SingleGModel;

    localModel: OM.SingleGModel = new OM.SingleGModel();

    @Prop() callback: any;

    created() {
        this.localModel = this.model;
    }

    get canSave(){
        return this.localModel.grams.key;
    }

    save() {
        if(!this.canSave)
            return;

        this.callback(this.localModel);
    }
    
}
