
import { Options, Vue } from 'vue-class-component';
import { CompanyClient, ProductionPhaseConfigurationClient, ProductionProcessClient } from '@/services/Services';
import EditModal from '../modals/editModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import SingleGModal from '../modals/singleGModal.vue';
import WattMinModal from '../modals/wattMinModal.vue';
import GWattMinModal from '../modals/gWattMinModal.vue';
import GTripleWattMinModal from '../modals/gTripleWattMinModal.vue';
import GWattMinM3Modal from '../modals/gWattMinM3Modal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class ProductionProcess extends Vue {

    items: OM.ProductionProcessConfigurationVM[] = [];
    productionPhases: OM.TextIdentifier[] = [];
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        Promise.all([
            ProductionProcessClient.getAllConfigurations(),
            ProductionPhaseConfigurationClient.getAllConfigurations()
        ])
        .then(xs => {
            this.items = xs[0];
            this.productionPhases = xs[1].map( x => {
                return {
                    identifier: x.identifier,
                    text: x.productionPhase
                }
            });
            this.loaded = true;
        })
    }

    editConfiguration(item: OM.ProductionProcessConfigurationVM) {
        if(!item){
            item = new OM.ProductionProcessConfigurationVM();
            item.isMandatory = true;
        }

        this.$opModal.show(EditModal, {
            model: item,
            productionPhases: this.productionPhases,
            callback: (updatedModel: OM.ProductionProcessConfigurationVM) => {
                ProductionProcessClient.editConfiguration(updatedModel)
                .then(x => {
                    this.init();
                    this.$opModal.closeLast();
                })
                .catch(y => {
                    this.$opModal.show(InfoModal, {
                        text: this.$localizationService.getLocalizedValue("Error", "Error"),
                        subtitle: y.Message,
                        deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                        denyCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            },
            callbackOptionalDetailed: (updatedModel: OM.ProductionProcessConfigurationVM, optionalDetailedProcessType: string) => {
                var checkSelectedValue = parseInt(optionalDetailedProcessType);

                if(checkSelectedValue == 0) {
                    this.$opModal.closeLast();
                    this.saveSingeGConfiguration(updatedModel);
                }
                if(checkSelectedValue == 1) {
                    this.$opModal.closeLast();
                    this.saveWattMinConfiguration(updatedModel);
                }
                if(checkSelectedValue == 2) {
                    this.$opModal.closeLast();
                    this.saveGWattMinConfiguration(updatedModel);
                }
                if(checkSelectedValue == 3) {
                    this.$opModal.closeLast();
                    this.saveGWattMinVariantConfiguration(updatedModel);
                }
                if(checkSelectedValue == 4) {
                    this.$opModal.closeLast();
                    this.saveGTripleWattMinConfiguration(updatedModel);
                }
                if(checkSelectedValue == 5) {
                    this.$opModal.closeLast();
                    this.saveGWattMinM3Configuration(updatedModel);
                }
            }
        })
    }

    saveSingeGConfiguration(updatedModel: OM.ProductionProcessConfigurationVM) {
        this.$opModal.show(SingleGModal, {
            model: new OM.SingleGModel(),
            callback: (updatedPropertyModel: OM.SingleGModel) => {
                var configModel = new OM.SingleGConfigurationVM();
                Object.assign(configModel, updatedModel);
                configModel.singleGModel = updatedPropertyModel;
                ProductionProcessClient.createOptionalDetailedSingleParamProcessConfiguration(configModel)
                .then(x => {
                    this.init();
                    this.$opModal.closeLast();
                })
                .catch(y => {
                    this.$opModal.show(InfoModal, {
                        text: this.$localizationService.getLocalizedValue("Error", "Error"),
                        subtitle: y.Message,
                        deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                        denyCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            }
        })
    }
    saveWattMinConfiguration(updatedModel: OM.ProductionProcessConfigurationVM) {
        this.$opModal.show(WattMinModal, {
            model: new OM.WattMinModel(),
            callback: (updatedPropertyModel: OM.WattMinModel) => {
                var configModel = new OM.WattMinConfigurationVM();
                Object.assign(configModel, updatedModel);
                configModel.wattMinModel = updatedPropertyModel;
                ProductionProcessClient.createOptionalDetailedDoubleParamProcessConfiguration(configModel)
                .then(x => {
                    this.init();
                    this.$opModal.closeLast();
                })
                .catch(y => {
                    this.$opModal.show(InfoModal, {
                        text: this.$localizationService.getLocalizedValue("Error", "Error"),
                        subtitle: y.Message,
                        deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                        denyCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            }
        })
    }
    saveGWattMinConfiguration(updatedModel: OM.ProductionProcessConfigurationVM) {
        this.$opModal.show(GWattMinModal, {
            model: new OM.GWattMinModel(),
            callback: (updatedPropertyModel: OM.GWattMinModel) => {
                var configModel = new OM.GWattMinConfigurationVM();
                Object.assign(configModel, updatedModel);
                configModel.gWattMinModel = updatedPropertyModel;
                ProductionProcessClient.createOptionalDetailedTripleParamProcessConfiguration(configModel)
                .then(x => {
                    this.init();
                    this.$opModal.closeLast();
                })
                .catch(y => {
                    this.$opModal.show(InfoModal, {
                        text: this.$localizationService.getLocalizedValue("Error", "Error"),
                        subtitle: y.Message,
                        deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                        denyCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            }
        })
    }
    saveGWattMinVariantConfiguration(updatedModel: OM.ProductionProcessConfigurationVM) {
        this.$opModal.show(GWattMinModal, {
            model: new OM.GWattMinVariantModel(),
            callback: (updatedPropertyModel: OM.GWattMinModel) => {
                var configModel = new OM.GWattMinVariantConfigurationVM();
                Object.assign(configModel, updatedModel);
                configModel.gWattMinVariantModel = updatedPropertyModel;
                ProductionProcessClient.createOptionalDetailedTripleVariantParamProcessConfiguration(configModel)
                .then(x => {
                    this.init();
                    this.$opModal.closeLast();
                })
                .catch(y => {
                    this.$opModal.show(InfoModal, {
                        text: this.$localizationService.getLocalizedValue("Error", "Error"),
                        subtitle: y.Message,
                        deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                        denyCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            }
        })
    }
    saveGTripleWattMinConfiguration(updatedModel: OM.ProductionProcessConfigurationVM) {
        this.$opModal.show(GTripleWattMinModal, {
            model: new OM.GTripleWattMinModel(),
            callback: (updatedPropertyModel: OM.GTripleWattMinModel) => {
                var configModel = new OM.GTripleWattMinConfigurationVM();
                Object.assign(configModel, updatedModel);
                configModel.gTripleWattMinModel = updatedPropertyModel;
                ProductionProcessClient.createOptionalDetailedTripleWattParamProcessConfiguration(configModel)
                .then(x => {
                    this.init();
                    this.$opModal.closeLast();
                })
                .catch(y => {
                    this.$opModal.show(InfoModal, {
                        text: this.$localizationService.getLocalizedValue("Error", "Error"),
                        subtitle: y.Message,
                        deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                        denyCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            }
        })
    }
    saveGWattMinM3Configuration(updatedModel: OM.ProductionProcessConfigurationVM) {
        this.$opModal.show(GWattMinM3Modal, {
            model: new OM.GWattMinM3Model(),
            callback: (updatedPropertyModel: OM.GWattMinM3Model) => {
                var configModel = new OM.GWattMinM3ConfigurationVM();
                Object.assign(configModel, updatedModel);
                configModel.gWattMinM3Model = updatedPropertyModel;
                ProductionProcessClient.createOptionalDetailedQuadrupleParamProcessConfiguration(configModel)
                .then(x => {
                    this.init();
                    this.$opModal.closeLast();
                })
                .catch(y => {
                    this.$opModal.show(InfoModal, {
                        text: this.$localizationService.getLocalizedValue("Error", "Error"),
                        subtitle: y.Message,
                        deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                        denyCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            }
        })
    }

}
